import React from "react";
import { m as motion } from "motion/react";
import { FormattedMessage } from "react-intl";

export interface HamburgerProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  label?: string;
  active?: boolean;
  onToggle?: (active: boolean) => void;
}

const Hamburger: React.FC<HamburgerProps> = ({
  label = undefined,
  onToggle = undefined,
  active = false,
  id,
  tabIndex = 0,
  ...any
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!onToggle) return;

    e.preventDefault();
    onToggle(!active);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key !== " " || !onToggle) return;

    e.preventDefault();
    onToggle(!active);
  };

  return (
    <button
      type="button"
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      id={id}
      {...any}
    >
      <svg
        aria-hidden="true"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.g
          fill="currentColor"
          fillRule="evenodd"
          animate={active ? "active" : "idle"}
          initial="idle"
        >
          <motion.rect
            width={32}
            height={2}
            x={8}
            y={13}
            variants={{
              idle: { rotate: "0deg", y: 0 },
              active: { rotate: "45deg", y: 10 },
            }}
          />
          <motion.rect
            width={28}
            height={2}
            x={12}
            y={23}
            variants={{
              idle: { opacity: 1, x: 0 },
              active: { opacity: 0, x: -2 },
            }}
          />
          <motion.rect
            width={32}
            height={2}
            x={8}
            y={33}
            variants={{
              idle: { rotate: "0deg", y: 0 },
              active: { rotate: "-45deg", y: -10 },
            }}
          />
        </motion.g>
      </svg>
      <span className="sr-only">
        {label ?? (
          <FormattedMessage
            id="toggle_navigation"
            defaultMessage="Toggle navigation"
          />
        )}
      </span>
    </button>
  );
};

export default Hamburger;
