/* eslint-disable react/no-unknown-property */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SpringOptions, useScroll, useSpring } from "motion/react";
import type { Group } from "three";
import {
  BackSide,
  BufferAttribute,
  Color,
  FrontSide,
  SRGBColorSpace,
  Vector3,
} from "three";
import { Line, OrbitControls, Text } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import LogoFallback from "@resources/images/logo-globe.png";
import { cn } from "@/helpers/classNames";
import { usePrefersReducedMotion } from "@/hooks/usePrefersReducesMotion";
import { TruncatedIcosidodecahedron } from "@/utils/polyhedra";

const DEBUG = false;
const SCALE = 55;

const Lights: React.FC = () => null;

const Globe: React.FC<{ dpr?: number; scrollMomentum: boolean }> = ({
  dpr = 2,
  scrollMomentum,
}) => {
  const faces = useMemo(
    () =>
      TruncatedIcosidodecahedron.face.filter((points) => points.length <= 6),
    [],
  );

  const getColors = useCallback(
    (key: "colors" | "backColors" = "colors") =>
      faces.map((face, i) => {
        let setting = TruncatedIcosidodecahedron[key][i];

        if (setting === undefined && key !== "colors") {
          setting = TruncatedIcosidodecahedron.colors[i];
        }

        if (setting === undefined) {
          setting = TruncatedIcosidodecahedron[key].fallback;
        }

        const color = new Color()
          .setHex(setting, SRGBColorSpace)
          .convertSRGBToLinear();

        if (key === "backColors") {
          color.offsetHSL(0, -0.7, 0.05);
        }

        return face.map(() => color.toArray()).flat();
      }),
    [faces],
  );

  const vertex = useMemo<{
    vertices: BufferAttribute;
    indices: BufferAttribute;
    edges: Array<{
      name: string;
      from: [number, number, number];
      to: [number, number, number];
    }>;
    colors: { front: BufferAttribute; back: BufferAttribute };
  }>(() => {
    const vertices = faces.map((face) =>
      face
        .map((i) =>
          TruncatedIcosidodecahedron.vertex[i].map((value) => value * SCALE),
        )
        .flat(),
    );

    let index = 0;

    const indices = faces.map((face) => {
      const faceIndices = new Array(face.length - 2)
        .fill(0)
        .map((_, i) => [index, index + i + 1, index + i + 2])
        .flat();

      index += face.length;

      return faceIndices;
    });

    const edges = TruncatedIcosidodecahedron.edge.map((edge, i) => {
      const [from, to] = edge.map((i) =>
        TruncatedIcosidodecahedron.vertex[i].map((value) => value * SCALE),
      );

      return {
        name: `edge-${i}`,
        from: from as [number, number, number],
        to: to as [number, number, number],
      };
    });

    return {
      vertices: new BufferAttribute(new Float32Array(vertices.flat()), 3),
      indices: new BufferAttribute(new Uint16Array(indices.flat()), 1),
      edges,
      colors: {
        front: new BufferAttribute(new Float32Array(getColors().flat()), 3),
        back: new BufferAttribute(
          new Float32Array(getColors("backColors").flat()),
          3,
        ),
      },
    };
  }, [faces, getColors]);

  const centers = useMemo(() => {
    if (!DEBUG) return [];

    return faces.map((points) =>
      points
        .map((i) =>
          TruncatedIcosidodecahedron.vertex[i].map((value) => value * 50),
        )
        .reduce<Vector3>(
          (prev, current) =>
            prev.add(new Vector3(current[0] - 3, current[1], current[2] + 2)),
          new Vector3(),
        )
        .divideScalar(points.length),
    );
  }, [faces]);

  const ref = useRef<Group>(null);

  const { scrollY } = useScroll();
  const physics: SpringOptions = { damping: 20, mass: 0.5, stiffness: 50 };
  const spring = useSpring(scrollY, physics);

  useFrame((_, delta) => {
    if (ref.current) {
      const springDelta =
        scrollMomentum && Math.abs(spring.get() ?? 0) > 0
          ? Math.abs((spring.getPrevious() ?? 0) - (spring.get() ?? 0))
          : 1;

      ref.current.rotation.y -= 0.002 + springDelta * 0.1 * delta;
    }
  });

  return (
    <group rotation={[0, 0, 0.075]}>
      <Lights />
      <group rotation={[-0.25, 0.5, -0.2]} ref={ref}>
        <mesh>
          <bufferGeometry>
            <bufferAttribute
              attach="attributes-position"
              {...vertex.vertices}
            />
            <bufferAttribute
              attach="attributes-color"
              {...vertex.colors.back}
            />
            <bufferAttribute attach="index" {...vertex.indices} />
          </bufferGeometry>
          <meshBasicMaterial
            toneMapped={false}
            fog={false}
            vertexColors
            side={BackSide}
            color={0xffffff}
          />
        </mesh>
        <mesh>
          <bufferGeometry>
            <bufferAttribute
              attach="attributes-position"
              {...vertex.vertices}
            />
            <bufferAttribute
              attach="attributes-color"
              {...vertex.colors.front}
            />
            <bufferAttribute attach="index" {...vertex.indices} />
          </bufferGeometry>
          <meshBasicMaterial
            toneMapped={false}
            fog={false}
            vertexColors
            side={FrontSide}
            color={0xffffff}
          />
          {centers.map((center, i) => (
            <Text
              key={center.toArray().join("_")}
              position={center}
              anchorX="center"
              anchorY="middle"
              scale={4}
              color={0xff0000}
            >
              {i}
            </Text>
          ))}
        </mesh>
        {dpr > 1 && (
          <group>
            {vertex.edges.map(({ name, from, to }) => (
              <Line
                key={name}
                points={[from, to]}
                color={0x000000}
                lineWidth={0.375}
              />
            ))}
          </group>
        )}
      </group>
    </group>
  );
};

const Logo: React.FC<
  {
    position?: "relative" | "absolute";
    scrollMomentum?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  position = "relative",
  scrollMomentum = false,
  className = undefined,
  ...props
}) => {
  const [dpr, setDpr] = useState(2);
  const [enable3d, setEnable3d] = useState(true);

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => setDpr(Math.min(window.devicePixelRatio, 2)), []);

  useEffect(() => {
    if (prefersReducedMotion) {
      setEnable3d(false);
      return;
    }

    try {
      const canvas = document.createElement("canvas");

      setEnable3d(
        !!window.WebGLRenderingContext &&
          !!(
            canvas.getContext("webgl") ||
            canvas.getContext("experimental-webgl")
          ),
      );
    } catch (e) {
      setEnable3d(false);
    }
  }, [prefersReducedMotion]);

  return (
    <div className={cn(position, className)} {...props}>
      {enable3d ? (
        <Canvas
          flat
          className="relative z-10 brightness-[1.5] saturate-[0.675]"
          dpr={dpr}
          camera={{
            fov: 20,
            aspect: 1,
            near: 1,
            far: 500,
            position: [0, 0, 350],
            rotation: [0, 0, 0.12],
          }}
        >
          <Globe scrollMomentum={scrollMomentum} dpr={dpr} />
          <OrbitControls
            enablePan={false}
            enableZoom={false}
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
          />
        </Canvas>
      ) : (
        <img
          src={LogoFallback}
          alt="Logo"
          className="relative z-10 w-full h-full"
        />
      )}
    </div>
  );
};

export default Logo;
